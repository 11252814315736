/*
 * Text Modification utilities CSS
 * ----------------------------------------------------------------------------
 * Put text style rules here that you want to apply globally 
 * These styles are for the entire app and not just one component
 */

.points{
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
}

.invalid-form-input-message{
  color: #f44336;
  font-size: 0.9rem;
  margin-top: 0.25rem;
  font-style: italic;
  font-weight: bold;
}
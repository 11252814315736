.p-picklist-item {
  padding: 0.25rem 1rem !important;
}

.custom-picklist .p-button:nth-child(2),
.custom-picklist .p-button:nth-child(4),
.custom-picklist .p-button:nth-child(6),
.custom-picklist .p-button:nth-child(8) {
  display: none !important;
}

.orders-list {
  height: 60vh;
  overflow: scroll;
}

.orders-list .p-card-content {
  padding: 0!important;
}

.order-card .p-card-content {
  padding: 0!important;
}

.order-card .p-card-body {
  padding: .75rem!important;
}